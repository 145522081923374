// 
// Colors
// 

//- Override and add to color system
$orange: #f4623a;
$brown: #5c4d42;
$tca_darkblue: #223963;
$tca_lightblue: #1370b3;

//- Override primary color
$primary: $tca_darkblue;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 3;

// 
// Spacing
// 

$navbar-height: 4.5rem;


// 
// Typography
// 

$font-family-sans-serif: "Merriweather Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-family-serif: "Merriweather", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-family-base: $font-family-serif;

$headings-font-family: $font-family-sans-serif;

$btn-font-family: $font-family-sans-serif;
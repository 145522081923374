html {
    height: 100%;
    scroll-padding-top: calc(#{$navbar-height} - 1px);
}

body {
    width: 100%;
    height: 100%;
}

//- Page section styling
.page-section {
    padding: 4rem 0;
}

// Custom utilities for this theme
.text-white-75 {
    color: fade-out($white, 0.25) !important;
}

.text-introclub {
  font-size: 20px;  
  color: white;
  font-weight: bold;
}


.bg-secondary {
    /*background-color: #002147 !important;*/
    background-color: $tca_darkblue !important;
  }

  .bg-grey {
    /*background-color: #002147 !important;*/
    background-color: lightgrey !important;
  }

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
    padding: 40px 0;
    overflow: hidden;
  }
  
  .section-bg {
    background-color: #f3f5fa;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    color: #37517e;
  }
  
  .section-title h2::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }
  
  .section-title h2::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #47b2e4;
    bottom: 0;
    left: calc(50% - 20px);
  }

  .section-title h3 {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
    padding-bottom: 10px;
    position: relative;
    color: #37517e;
  }
  
  .section-title h3::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }
  
  .section-title h3::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #47b2e4;
    bottom: 0;
    left: calc(50% - 20px);
  }
  
  .section-title p {
    margin-bottom: 0;
  }

  .section-page {
    padding-top: calc(20px + $navbar-height);
  }


  .imgnews {
    height:100%;
	  width:100%;
  }


.tennis-background {
    background: url("../assets/img/tennis_background.jpg");

      background-position: center center;
      background-repeat: no-repeat;      
      background-size: cover;  
      -moz-background-size: cover;      
      -webkit-background-size: cover;  
      -o-background-size: cover;

      height: 50vh;
      padding-bottom: 0;
  }

  .padel-background {
    background: url("../assets/img/padel_background2.jpg");

      background-position: center center;
      background-repeat: no-repeat;      
      background-size: cover;  
      -moz-background-size: cover;      
      -webkit-background-size: cover;  
      -o-background-size: cover;

      height: 50vh;
      padding-bottom: 0;
  }

  .club-background {
    background: url("../assets/img/header1.JPG");

      background-position: center center;
      background-repeat: no-repeat;      
      background-size: cover;  
      -moz-background-size: cover;      
      -webkit-background-size: cover;  
      -o-background-size: cover;

      height: 50vh;
      padding-bottom: 0;
  }


header.masthead {
    //padding-top: 10rem;
    //padding-bottom: calc(10rem - #{$navbar-height});    

    // replace the masthead.jpg image in the /assets/img directory to change the image
    // if the image name change, make sure to update the url path below!

    background: linear-gradient(to bottom,
        #{fade-out($tca_lightblue, 0.2)} 0%,
        #{fade-out($tca_lightblue, 0.2)} 100%),
    url("../assets/img/header1.webp");

    background-position: center center;
    background-repeat: no-repeat;
    //background-attachment: fixed;
    background-size: cover;  
    -moz-background-size: cover;      
    -webkit-background-size: cover;  
    -o-background-size: cover;
    
    

    height: 100vh;
        min-height: 40rem;
        padding-top: $navbar-height;
        padding-bottom: 0;

        p {
            font-size: 1.15rem;
        }

        h1 {
            font-size: 3rem;
        }

    @include media-breakpoint-up(sm) {
        
    }

    @include media-breakpoint-up(xl) {
        h1 {
            font-size: 3.5rem;
        }
    }
    
}
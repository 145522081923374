.contact-details {
	margin:70px 0;
}
.contact-details h2 {
	font-size:20px;
}
.contact-details h2 span {
	display:block;
	color:#545454;
	text-transform:uppercase;
	letter-spacing:1px;
	font-size:14px;
	margin-top:15px;
}
.contact-wrap {
	position:relative;
}
#googlemaps {
	width:100%;
	height:300px;
	left:15px;
	bottom:25px;
}
.contact {
	margin-top:-20px!important;
}
.contact label {
	float:left;
}
.dark-box {
	background: #F3F5F8;
	-webkit-box-shadow: 0px 3px 0px 0px $tca_darkblue;
	-moz-box-shadow: 0px 3px 0px 0px $tca_darkblue;
	box-shadow: 0px 3px 0px 0px $tca_darkblue;
	padding:20px 0;
}
.dark-box:hover,.dark-box:focus {
	-webkit-box-shadow: 0px 3px 0px 0px $tca_lightblue;
	-moz-box-shadow: 0px 3px 0px 0px $tca_lightblue;
	box-shadow: 0px 3px 0px 0px $tca_lightblue;
}
.box-hover {
	transition:all 500ms ease-in-out;
	-webkit-transition:all 500ms ease-in-out;
	-moz-transition:all 500ms ease-in-out;
	-o-transition:all 500ms ease-in-out;
}
.box-hover:hover,.box-hover:focus {
	transform:translate(0,-15px);
	-webkit-transform:translate(0,-15px);
	-ms-transform:translate(0,-15px);
}